import { Path } from "~/router";

/**
 * Generates a sitemap for a conversanational AI to understand the web page
 *
 * This is in the application to couple it closely with the typesystem of the router
 *
 * It might need to be moved at some point - it is still preliminary development and exploration
 */
export const siteMapForAi = () => {
  /**
   * Some thoughts here:
   *
   * - The typing ensures that all paths are represented. But we don't have time all the
   *   time to make descriptions. in these cases we can set it to null
   * - We might need to be really diligent with path naming. Eg. the staff pages that are under
   *   the user path - it might confuse the AI.
   * - These will change over time. That is completely fine. We don't expect the user to go back to old chats.
   *   If they do, we can make them stale an remove them.
   */
  const siteMap: Record<Path, string | null> = {
    "/": "Shows the login screen",
    "/dashboard": "Shows an overview of all transactions",
    "/dashboard/customers": "Shows an overview of all customers",
    "/dashboard/customers/:userId":
      "Shows customer information given a user ID. This is only used if the customer is not a part of a transaction",
    "/dashboard/transaction/:transactionId":
      "Shows transaction information given a transactionId",
    "/dashboard/transaction/:transactionId/co-buyer":
      "Shows information about the co-buyer of a transaction",

    "/dashboard/transaction/:transactionId/deal": "N/A",
    "/dashboard/transaction/:transactionId/co-buyer/add": "N/A",
    "/profile":
      "Profile page for currently logged in user. Here they can manage 2FA, etc.",
    "/dashboard/staff": "Staff page, only for users with staff access",
    "/dashboard/staff/:userId": "Admin page for single staff page",
    "/dashboard/reports": "Admin page for displaying reports ",
    "/dashboard/reports/sales-ranking":
      "Admin page for displaying more detailed sales ranking",

    // The following need explanations when we go in full production

    "/dashboard/transaction/:transactionId/customer/:userId": null,

    // Vehicle pages
    "/dashboard/transaction/:transactionId/vehicle/purchase": null,
    "/dashboard/transaction/:transactionId/vehicle/trade": null,

    // Deal jacket pages
    "/dashboard/transaction/:transactionId/deal-jacket": null,
    "/dashboard/transaction/:transactionId/deal-jacket/document/:documentId":
      null,
    "/dashboard/transaction/:transactionId/deal-jacket/formSubmission/:formSubmissionId":
      null,
    "/dashboard/transaction/:transactionId/deal-jacket/view": null,

    // Customer pages
    "/dashboard/customers/:userId/:transactionId/files": null,
    "/dashboard/customers/:userId/:transactionId/files/document/:documentId":
      null,
    "/dashboard/customers/:userId/:transactionId/files/formSubmission/:formSubmissionId":
      null,
  };

  return siteMap;
};
