import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { DataStatus, Tooltip } from "@thedealersconcierge/components";
import { IntellicheckRawPersistedSchema } from "@thedealersconcierge/lib/codecs/agility-credit";
import classNames from "classnames";
import { FC, Fragment, useState } from "react";
import Button from "~/components/Button";
import { gqlQueryClient } from "~/lib/backend";

const toHuman: Record<string, string> = {
  addressMatchDetails: "Address Match Details",
  nameMatchDetails: "Name Match Details",
  isEyeColorMatch: "Eye Color Match",
  isNationalityMatch: "Nationality Match",
  heightMatchDetails: "Height Match Details",
  dlClassMatchDetails: "Driver's License Class Match Details",
  realIdMatchDetails: "Real ID Match Details",
  dlEndorsementMatchDetails: "Driver's License Endorsement Match Details",
  expirationDateMatchDetails: "Expiration Date Match Details",
  isIssueDateMatch: "Issue Date Match",
  isDocumentNumberMatch: "Document Number Match",
  isNameMatch: "Name Match",
  dlRestrictionsMatchDetails: "Driver's License Restrictions Match Details",
  weightMatchDetails: "Weight Match Details",
  documentNumberMatchDetails: "Document Number Match Details",
  issuerNameMatchDetails: "Issuer Name Match Details",
  isHeightMatch: "Height Match",
  sexMatchDetails: "Gender Match Details",
  dobMatchDetails: "Date of Birth Match Details",
  issueDateMatchDetails: "Issue Date Match Details",
  isAddressMatch: "Address Match",
  isSexMatch: "Gender Match",
  isExpirationDateMatch: "Expiration Date Match",
  isDlClassMatch: "Driver's License Class Match",
  isDlEndorsementMatch: "Driver's License Endorsement Match",
  isRealIdMatch: "Real ID Match",
  eyeColorMatchDetails: "Eye Color Match Details",
  isWeightMatch: "Weight Match",
  isDobMatch: "Date of Birth Match",
  isIssuerNameMatch: "Issuer Name Match",
  isDlRestrictionsMatch: "Driver's License Restrictions Match",
  isCountryCodeMatch: "Country Code Match",
};

type Props = {
  transactionId: string;
  userId: string;
};

interface VerificationItem {
  title: string;
  statusText?: string;
  itemPassed?: boolean;
}

const processLicenseData = (
  rawData: IntellicheckRawPersistedSchema
): VerificationItem[] => {
  return Object.entries(rawData.ocr_match.data).map(([key, value]) => {
    const description = toHuman[key];

    const subText = typeof value === "boolean" ? undefined : value?.details;
    const passed =
      typeof value === "boolean"
        ? value
        : value?.similarityScore && value.similarityThreshold
          ? // There are some values where threshold is 100, so I interpret that equals is also good enough
            value.similarityScore >= value.similarityThreshold
          : undefined;

    const e: VerificationItem = {
      title: description,
      statusText: subText ?? undefined,
      itemPassed: passed,
    };

    return e;
  });
};

const VerificationItem: FC<{
  number: number;
  title: string;
  status?: string;
  itemPassed?: boolean;
}> = ({ number, title, status, itemPassed }) => {
  return (
    <div className="flex items-center bg-gray-100 p-4 rounded-md mb-2">
      <div className="flex-shrink-0 w-8 text-center font-medium">{number}</div>
      <div className="flex-grow ml-4">
        <div className="font-medium text-gray-700">{title}</div>
        <div
          className={classNames({
            "text-green-600": itemPassed,
          })}
        >
          {status}
        </div>
      </div>
      <div className="flex-shrink-0">
        <Tooltip
          content={
            <p>
              {typeof itemPassed === "undefined"
                ? "No data is available"
                : itemPassed
                  ? "Item passed"
                  : "Item did not pass"}
            </p>
          }
          anchor={
            <div>
              <DataStatus
                status={
                  typeof itemPassed === "undefined"
                    ? "NO_DATA"
                    : itemPassed
                      ? "COMPLETE"
                      : "INCOMPLETE"
                }
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

/**
 * TODO: Move this into an individual page
 *
 * For our AI efforts we are going to prefer direct routes over modal or other
 * in page visual elements (When there is significant data to show).
 */
export default function IdvModal({ transactionId, userId }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useQuery({
    // We can safely reuse the same queryKey for both the trade and purchase vehicle
    queryKey: ["additional-idv", transactionId, userId],
    queryFn: async () =>
      gqlQueryClient()({
        customer: [
          {
            transactionId,
            userId,
          },
          {
            compliance: {
              hasRequestedIdv: { verificationFields: true },
            },
          },
        ],
      }),
  });

  const verificationFields =
    IntellicheckRawPersistedSchema.optional().safeParse(
      data?.customer?.compliance.hasRequestedIdv.verificationFields
    );

  const verificationItems =
    verificationFields.success && verificationFields.data
      ? processLicenseData(verificationFields.data)
      : undefined;

  return (
    <>
      <Button
        variant="TEXT_ONLY"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        ID Verifications
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-modal"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="text-heading-2">
                    ID Verification
                  </Dialog.Title>

                  <div className="h-[50vh] overflow-auto my-5">
                    {verificationItems?.map((item, idx) => (
                      <VerificationItem
                        key={idx}
                        number={idx}
                        title={item.title}
                        status={item.statusText}
                        itemPassed={item.itemPassed}
                      />
                    ))}
                  </div>

                  <div className="flex flex-col space-y-2 p-4">
                    <div className="text-center text-sm">
                      Source: IntelliCheck
                    </div>
                  </div>
                  <div className="mt-4 flex flex-row justify-end w-full space-x-4">
                    <button
                      type="button"
                      className="button-primary"
                      onClick={() => setIsOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
