import { captureException } from "@sentry/react";
import {
  Button,
  useConfirmationModal,
  UserConfirmationModal,
} from "@thedealersconcierge/components";
import { useState } from "react";
import { toast } from "react-toastify";
import { Fragment } from "react/jsx-runtime";
import { VehicleLifeCycleStage } from "~/__generated__/backend/zeus";
import SectionDataRow from "~/components/SectionDataRow";
import CarBackIcon from "~/components/icons/CarBackIcon";
import CarFrontIcon from "~/components/icons/CarFrontIcon";
import CarInspectionIcon from "~/components/icons/CarInspectionIcon";
import CarOdometerIcon from "~/components/icons/CarOdometerIcon";
import CarSeatIcon from "~/components/icons/CarSeatIcon";
import CarSideIcon from "~/components/icons/CarSideIcon";
import TradeVehicleIcon from "~/components/icons/TradeVehicleIcon";
import { gqlMutationClient } from "~/lib/backend";
import {
  getReadableBodyType,
  getReadableVehicleCondition,
} from "~/lib/enumReadable";
import { queryClient } from "~/lib/query";
import { useNavigate } from "~/router";
import { TransactionQueryType } from "../_queries/transactionQuery";
import RecallCheckModal from "./RecallCheckModal";
import VinAuditModal from "./VinAuditModal";

type Props = {
  transaction: TransactionQueryType["transaction"];
};

export default function TransactionTradeVehicle({ transaction }: Props) {
  const navigate = useNavigate();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const tradeVehicle = transaction?.tradeVehicle;
  const { ConfirmationModalComponent, showWarningModal } =
    useConfirmationModal();

  const buyer = transaction?.buyer;

  const handleEditTradeVehicle = async () => {
    await navigate(
      {
        pathname: "/dashboard/transaction/:transactionId/vehicle/trade",
      },
      {
        params: {
          transactionId: transaction?.id ?? "no-transaction-id",
        },
      }
    );
  };

  /**
   * Mutations to remove trade vehicle
   */
  const handleRemoveTradeVehicle = async () => {
    try {
      setIsRemoveLoading(true);
      if (!tradeVehicle?.id) throw Error("Invalid vehicle data");

      const removeresp = await gqlMutationClient()({
        dealershipRemoveTradeVehicle: [
          {
            vehicleId: tradeVehicle?.id,
          },

          {
            __typename: true,
            "...on GraphQLError": { message: true },
            "...on MutationDealershipRemoveTradeVehicleSuccess": {
              data: { status: true },
            },
          },
        ],
      });

      if (
        !removeresp.dealershipRemoveTradeVehicle ||
        removeresp.dealershipRemoveTradeVehicle.__typename === "GraphQLError"
      ) {
        throw Error(
          removeresp.dealershipRemoveTradeVehicle?.message ?? undefined
        );
      }

      await queryClient.invalidateQueries({
        queryKey: ["transaction", transaction?.id],
      });

      toast.success("Successfully removed trade vehicle");
    } catch (e) {
      // Handles unexpected errors
      console.error(e);
      captureException(e);

      if (e instanceof Error && e.message) {
        toast.error(e.message);
      } else {
        toast.error("Failed to remove vehicle data");
      }
    } finally {
      setIsRemoveLoading(false);
    }
  };

  return (
    <Fragment>
      <UserConfirmationModal
        isOpen={isConfirmationModalOpen}
        message={
          <span>
            You are about to change the vehicle for trade, even though the
            compliance forms have already been executed by the customer.
            <br />
            <br />
            Please note:
            <br />
            <ul className="list-disc list-outside pl-spacing-04 tablet:pl-spacing-05">
              <li>
                Changing the vehicle will require{" "}
                <b>cancelling the previously executed forms</b>.
              </li>

              <li>
                These forms will be removed from the deal jacket for this
                transaction.
              </li>

              <li>
                The customer will be required to sign a <b>new set of forms</b>.
              </li>
            </ul>
            <br />
            Are you sure you want to proceed with the change?
          </span>
        }
        onClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        onConfirm={() => {
          setIsConfirmationModalOpen(false);
          handleEditTradeVehicle();
        }}
      />

      {/* 
        This modal is used to display verbose information to inform user consequences removing trade vehicle
        when the transaction has already filled compliance forms
      */}
      <UserConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        message={
          <span>
            You are about to <b>REMOVE</b> the vehicle for trade, even though
            the compliance forms have already been executed by the customer.
            <br />
            <br />
            Please note:
            <br />
            <ul className="list-disc list-outside pl-spacing-04 tablet:pl-spacing-05">
              <li>
                Removing the vehicle will require{" "}
                <b>cancelling the previously executed forms</b>.
              </li>

              <li>
                These forms will be removed from the deal jacket for this
                transaction.
              </li>

              <li>
                The customer will be required to sign a <b>new set of forms</b>.
              </li>
            </ul>
            <br />
            Are you sure you want to proceed to remove the vehicle?
          </span>
        }
        onClose={() => {
          setIsDeleteConfirmationModalOpen(false);
        }}
        onConfirm={() => {
          setIsDeleteConfirmationModalOpen(false);
          handleRemoveTradeVehicle();
        }}
      />

      {/* This modal is used to simply ask the user confirmation to remove trade vehicle when there is no filled compliance forms  */}
      {ConfirmationModalComponent}

      <div className="rounded-2xl bg-white shadow-md p-8 space-y-8">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-4 justify-between w-full">
            <div className="flex flex-row space-x-2 items-center">
              <div className="relative">
                <TradeVehicleIcon className="w-5 aspect-square text-dark-gray" />
              </div>

              <h2 className="text-heading-2 text-very-dark-gray">
                Vehicle for Trade
              </h2>
            </div>

            <div className="flex flex-row space-x-4">
              {tradeVehicle?.id && transaction?.id && (
                <>
                  <VinAuditModal transactionId={transaction.id} />
                  <RecallCheckModal
                    vehicleRole="TRADE"
                    transactionId={transaction.id}
                  />
                </>
              )}

              <Button
                variant="GHOST"
                size="XSMALL"
                onClick={() => {
                  /**
                   * If the compliance forms have been previously requested,
                   * we want the user to confirm they understand the consequences.
                   */
                  if (
                    transaction?.latestPrePurchaseCollection?.edges?.[0]?.node
                      ?.createdAt ||
                    transaction?.latestPostPurchaseCollection?.edges?.[0]?.node
                      ?.createdAt
                  ) {
                    setIsConfirmationModalOpen(true);
                  } else {
                    handleEditTradeVehicle();
                  }
                }}
                label={tradeVehicle ? "Edit" : "Add"}
              />

              {tradeVehicle && (
                <Button
                  variant="GHOST"
                  className="text-danger"
                  size="XSMALL"
                  onClick={async () => {
                    /**
                     * If the compliance forms have been previously requested,
                     * we want the user to confirm they understand the consequences.
                     */
                    if (
                      transaction?.latestPrePurchaseCollection?.edges?.[0]?.node
                        ?.createdAt ||
                      transaction?.latestPostPurchaseCollection?.edges?.[0]
                        ?.node?.createdAt
                    ) {
                      setIsDeleteConfirmationModalOpen(true);
                    } else {
                      const doRemoval = await showWarningModal({
                        message: "Are you sure to remove Trade Vehicle?",
                      });
                      if (doRemoval) handleRemoveTradeVehicle();
                    }
                  }}
                  label="Remove"
                />
              )}
            </div>
          </div>

          {/**
           * TODO: Hook up CARFAX
           */}
          {/* <Button variant="SECONDARY">CARFAX</Button> */}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-4">
              <SectionDataRow label="Registered Owner" value={"-"} />

              <SectionDataRow
                label="Type"
                value={(() => {
                  if (!tradeVehicle) return "-";
                  if (!tradeVehicle?.lifeCycleStage) return "-";

                  if (
                    tradeVehicle.lifeCycleStage ===
                    VehicleLifeCycleStage.IS_USED
                  ) {
                    return "Used";
                  }
                  return "New";
                })()}
              />

              <SectionDataRow label="VIN#" value={tradeVehicle?.vin ?? "-"} />

              <SectionDataRow
                label="Body Type"
                value={
                  tradeVehicle?.bodyType
                    ? getReadableBodyType(tradeVehicle.bodyType)
                    : "-"
                }
              />

              <SectionDataRow label="Make" value={tradeVehicle?.make ?? "-"} />
            </div>

            <div className="flex flex-col gap-4">
              <SectionDataRow
                label="Model"
                value={tradeVehicle?.model ?? "-"}
              />

              <SectionDataRow label="Year" value={tradeVehicle?.year ?? "-"} />

              <SectionDataRow
                label="Color"
                value={tradeVehicle?.color ?? "-"}
              />

              <SectionDataRow
                label="Condition"
                value={
                  tradeVehicle?.condition
                    ? getReadableVehicleCondition(tradeVehicle.condition)
                    : "-"
                }
              />

              <SectionDataRow
                label="Mileage"
                value={tradeVehicle?.mileage?.toLocaleString() ?? "-"}
              />

              <SectionDataRow
                label="Current monthly payment"
                value={
                  buyer?.currentMonthlyPayment
                    ? `$${buyer.currentMonthlyPayment.toLocaleString()}`
                    : "-"
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
            <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
              {tradeVehicle?.pictureFront?.url && (
                <img
                  className="w-full h-full"
                  style={{ objectFit: "cover" }}
                  src={tradeVehicle.pictureFront.url}
                />
              )}

              {!tradeVehicle?.pictureFront?.url && (
                <div className="flex flex-col items-center space-y-4 pt-2">
                  <CarFrontIcon className="h-14 text-dark-gray" />

                  <div className="text-block text-dark-gray">Front</div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
              {tradeVehicle?.pictureBack?.url && (
                <img
                  className="w-full h-full"
                  style={{ objectFit: "cover" }}
                  src={tradeVehicle.pictureBack.url}
                />
              )}

              {!tradeVehicle?.pictureBack?.url && (
                <div className="flex flex-col items-center space-y-4 pt-2">
                  <CarBackIcon className="h-14 text-dark-gray" />

                  <div className="text-block text-dark-gray">Back</div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
              {tradeVehicle?.pictureLeftSide?.url && (
                <img
                  className="w-full h-full"
                  style={{ objectFit: "cover" }}
                  src={tradeVehicle.pictureLeftSide.url}
                />
              )}

              {!tradeVehicle?.pictureLeftSide?.url && (
                <div className="flex flex-col items-center space-y-4 pt-2">
                  <CarSideIcon className="h-12 text-dark-gray" />

                  <div className="text-block text-dark-gray">Left Side</div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
              {tradeVehicle?.pictureRightSide?.url && (
                <img
                  className="w-full h-full"
                  style={{ objectFit: "cover" }}
                  src={tradeVehicle.pictureRightSide.url}
                />
              )}

              {!tradeVehicle?.pictureRightSide?.url && (
                <div className="flex flex-col items-center space-y-4 pt-2">
                  <CarSideIcon className="h-12 text-dark-gray -scale-x-100" />

                  <div className="text-block text-dark-gray">Right Side</div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
              {tradeVehicle?.pictureInterior?.url && (
                <img
                  className="w-full h-full"
                  style={{ objectFit: "cover" }}
                  src={tradeVehicle.pictureInterior.url}
                />
              )}

              {!tradeVehicle?.pictureInterior?.url && (
                <div className="flex flex-col items-center space-y-4 pt-2">
                  <CarSeatIcon className="h-14 text-dark-gray -scale-x-100" />

                  <div className="text-block text-dark-gray">Interior</div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
              {tradeVehicle?.pictureOdometer?.url && (
                <img
                  className="w-full h-full"
                  style={{ objectFit: "cover" }}
                  src={tradeVehicle.pictureOdometer.url}
                />
              )}

              {!tradeVehicle?.pictureOdometer?.url && (
                <div className="flex flex-col items-center space-y-4 pt-2">
                  <CarOdometerIcon className="h-14 text-dark-gray -scale-x-100" />

                  <div className="text-block text-dark-gray">Odometer</div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
              {tradeVehicle?.pictureVinNumber?.url && (
                <img
                  className="w-full h-full"
                  style={{ objectFit: "cover" }}
                  src={tradeVehicle.pictureVinNumber.url}
                />
              )}

              {!tradeVehicle?.pictureVinNumber?.url && (
                <div className="flex flex-col items-center space-y-4 pt-2">
                  <CarInspectionIcon className="h-14 text-dark-gray" />

                  <div className="text-block text-dark-gray">VIN Number</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
