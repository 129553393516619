import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { UserDealershipSchema } from "@thedealersconcierge/lib/codecs/schema/user";
import stringify from "json-stable-stringify";
import {
  FormCollectionType,
  GraphQLTypes,
  InputType,
  OrderBy,
  Selector,
} from "~/__generated__/backend/zeus";
import { gqlQueryClient } from "~/lib/backend";
import { queryClient } from "~/lib/query";
import { addressSelector } from "~/selectors/addressSelector";

import { employeeUserSelector } from "~/selectors/employeeSelector";
import { vehicleSelector } from "~/selectors/vehicleSelector";

const customerSelector = (userDealership?: UserDealershipSchema) =>
  Selector("Customer")({
    formSubmissions: [
      {},
      {
        __directives: `@include(if:${permissionChecker("viewDealJacket", userDealership)})`,
        edges: {
          node: {
            status: true,
            type: true,
          },
        },
      },
    ],

    residentialAddresses: [
      { first: 20 },
      {
        edges: {
          node: addressSelector,
        },
      },
    ],
    vehicleRegistrationAddress: addressSelector,

    selfieCapture: {
      url: true,
    },

    userId: true,
    firstName: true,
    lastName: true,

    hasInsurance: true,
    hasCoBuyer: true,
    wantsRegistrationAtSameAddress: true,
    wantsToPreQualify: true,
    hasPrequalApplication: true,
    hasSubmittedHardCreditApplication: true,

    // Understood to be the newest active application
    hardCreditApplications: [
      { first: 1, orderBy: { createdAt: OrderBy.Desc } },
      {
        edges: {
          node: {
            id: true,
            createdAt: true,
            formSubmissionId: true,
          },
        },
      },
    ],
    prequalApplications: [
      { first: 1 },
      {
        edges: {
          __directives: `@include(if:${permissionChecker("viewCreditReports", userDealership)})`,
          node: {
            newestReportPrequalify: {
              score: true,
              resultCode: true,
            },
            // newestReportOfac: {
            //   ofacStatus: true,
            // },
          },
        },
      },
    ],
    user: {
      email: true,
      phoneNumber: true,
    },
    idCards: {
      captureFront: { id: true },
      captureBack: { id: true },
    },
    currentMonthlyPayment: true,
  });

export type CustomerType = InputType<
  GraphQLTypes["Customer"],
  ReturnType<typeof customerSelector>
>;

const transactionSelector = (
  transactionId: string,
  userDealership?: UserDealershipSchema
) => {
  return Selector("Query")({
    transaction: [
      {
        id: transactionId,
      },
      {
        id: true,
        status: true,
        source: true,

        // Used for the transction title
        title: true,

        // Credit application stuff
        hardCreditApplDmsSubmittedAt: true,
        hardCreditApplDmsSubmittedTo: true,

        // Be aware that these do not necesarrily match the customer that is
        // also selected
        buyerId: true,
        buyer: {
          formSubmissions: [
            {},
            {
              __directives: `@include(if:${permissionChecker("viewDealJacket", userDealership)})`,
              edges: {
                node: {
                  status: true,
                  type: true,
                },
              },
            },
          ],

          residentialAddresses: [
            { first: 20 },
            {
              edges: {
                node: addressSelector,
              },
            },
          ],
          vehicleRegistrationAddress: addressSelector,

          selfieCapture: {
            url: true,
          },

          userId: true,
          firstName: true,
          lastName: true,

          hasInsurance: true,
          hasCoBuyer: true,
          wantsRegistrationAtSameAddress: true,
          wantsToPreQualify: true,
          hasPrequalApplication: true,
          hasSubmittedHardCreditApplication: true,

          // Understood to be the newest active application
          hardCreditApplications: [
            { first: 1, orderBy: { createdAt: OrderBy.Desc } },
            {
              edges: {
                node: {
                  id: true,
                  createdAt: true,
                  formSubmissionId: true,
                },
              },
            },
          ],

          user: {
            email: true,
            phoneNumber: true,
          },
          idCards: {
            captureFront: { id: true },
            captureBack: { id: true },
          },
          currentMonthlyPayment: true,
          prequalApplications: [
            { first: 1 },
            {
              edges: {
                __directives: `@include(if:${permissionChecker("viewCreditReports", userDealership)})`,
                node: {
                  newestReportPrequalify: {
                    score: true,
                    resultCode: true,
                  },
                },
              },
            },
          ],
        },
        coBuyerId: true,
        coBuyer: customerSelector(userDealership),

        customerSharedData: {
          coBuyerName: true,
        },
        salesManager: employeeUserSelector,
        fniManager: employeeUserSelector,
        salesPerson: employeeUserSelector,
        bdc: employeeUserSelector,
        tradeVehicle: vehicleSelector,
        vehicle: vehicleSelector,
        financeType: true,
        price: true,
        downPayment: true,

        isLeaseBuyOut: true,
        cdkDmsDealId: true,
        dealership: {
          hasEnabledCdkDms: true,
          hasEnabledPostPurchaseDocs: true,
        },

        consumerRebatesInUsdCents: true,
        salesPriceInUsdCents: true,
        downPaymentInUsdCents: true,
        salesTaxInMiliBsp: true,
        tradeInPriceInUsdCents: true,
        monthlyLeasePaymentInUsdCents: true,
        milesPerYearInLease: true,
        leaseTermInMonths: true,
        dealershipCommitment: true,
        __alias: {
          latestPrePurchaseCollection: {
            formSubmissionCollections: [
              {
                filter: {
                  type: {
                    equals: FormCollectionType.PRE_PURCHASE,
                  },
                },
                orderBy: {
                  createdAt: OrderBy.Desc,
                },
                first: 1,
              },
              {
                edges: {
                  node: {
                    createdAt: true,
                    status: true,
                  },
                },
              },
            ],
          },
          latestPostPurchaseCollection: {
            formSubmissionCollections: [
              {
                filter: {
                  type: {
                    equals: FormCollectionType.POST_PURCHASE,
                  },
                },
                orderBy: {
                  createdAt: OrderBy.Desc,
                },
                first: 1,
              },
              {
                edges: {
                  node: {
                    createdAt: true,
                    status: true,
                  },
                },
              },
            ],
          },
          // Tried to be descriptive. Feel free to change if you find a better naming.
          latestTransactionLogThatShouldRetriggerComplianceDocs: {
            logs: [
              {
                orderBy: {
                  createdAt: OrderBy.Desc,
                },
                filter: {
                  shouldRetriggerComplianceDocs: true,
                },
                first: 1,
              },
              {
                edges: {
                  node: {
                    createdAt: true,
                  },
                },
              },
            ],
          },
        },
      },
    ],
  });
};

/**
 * Query meant for viewing a query and a transaction
 *
 * @param transactionId
 * @returns
 */
export const transactionQuery = (
  transactionId: string,
  userDealership?: UserDealershipSchema
) => {
  return {
    queryKey: ["transaction", transactionId, stringify(userDealership ?? {})],
    queryFn: async () =>
      gqlQueryClient()(transactionSelector(transactionId, userDealership)),
  };
};

export const refetchTransactionQuery = async (transactionId: string) => {
  await queryClient.refetchQueries({
    queryKey: ["transaction", transactionId],
  });
};

export type TransactionQueryType = InputType<
  GraphQLTypes["Query"],
  ReturnType<typeof transactionSelector>
>;
