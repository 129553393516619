import { useSuspenseQuery } from "@tanstack/react-query";
import {
  Button,
  DataStatus,
  Tooltip,
  UserConfirmationModal,
} from "@thedealersconcierge/components";
import { useAtomValue } from "jotai";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ComplianceStatus, Selector } from "~/__generated__/backend/zeus";
import Banner from "~/components/Banner";
import Spinner from "~/components/Spinner";
import withSuspense from "~/components/withSuspense";
import { gqlMutationClient, gqlQueryClient } from "~/lib/backend";
import dealershipQuery from "~/query/dealershipQuery";
import { dealershipAtom } from "~/state";
import IdvModal from "./IdvModal";

const selectAll = Selector("ComplianceField")({
  title: true,
  description: true,
  status: true,
});

const toStatus = (t: ComplianceStatus) => {
  switch (t) {
    case ComplianceStatus.OK:
      return "COMPLETE";
    case ComplianceStatus.WARN:
      return "REQUIRES_ATTENTION";
    case ComplianceStatus.ERROR:
      return "REQUIRES_ATTENTION";
    case ComplianceStatus.INFO:
      return "INCOMPLETE";
  }
};

const TransactionCompliance: FC<{
  transactionId: string;
  userId: string;
}> = ({ transactionId, userId }) => {
  const { t } = useTranslation();

  // Left here intentionally to exemplify how to debug and test suspense
  // useSuspenseDebug(10000);

  const activeDealership = useAtomValue(dealershipAtom);
  const { data: dealerData } = useSuspenseQuery(
    dealershipQuery(activeDealership?.activeDealershipPerms.dealershipId)
  );

  const { data: compliance, refetch } = useSuspenseQuery({
    // Refetch every 10 seconds
    // Note: remove this is we see performance issues
    refetchInterval: 7000,
    queryKey: ["compliance", transactionId],
    queryFn: async () => {
      return await gqlQueryClient()({
        customer: [
          {
            transactionId,
            userId,
          },
          {
            compliance: {
              hasRequestedIdv: selectAll,
              id: selectAll,
              idQuizSummary: selectAll,
              ofacStatus: selectAll,
              redFlagStatus: selectAll,
              recalls: selectAll,
            },
          },
        ],
      });
    },
  });

  const [isUserConfirmationModalOpen, setIsUserConfirmationModalOpen] =
    useState(false);
  const requestIdv = () => {
    try {
      setIsUserConfirmationModalOpen(false);
      const requestPromise = gqlMutationClient()({
        requestIdentityVerification: [
          {
            transactionId,
            userId,
          },
          {
            __typename: true,
            "...on GraphQLError": {
              message: true,
            },
            "...on MutationRequestIdentityVerificationSuccess": {
              data: {
                status: true,
              },
            },
          },
        ],
      }).then(() => refetch());

      toast.promise(requestPromise, {
        error: "An error happened",
        pending: "Requesting ID verification",
        success:
          "ID Verification was requested. It can take a while for the result to be available",
      });
    } catch (e) {
      toast.error("Some error happened");
    }
  };

  if (!compliance.customer) {
    return (
      <div className="rounded-2xl bg-white shadow-md p-8 space-y-8 size-full">
        <Banner variant="WARNING">
          <p>{t("There is some data you might not have access to see")}</p>
        </Banner>
      </div>
    );
  }

  return (
    <div className="rounded-2xl bg-white shadow-md p-8 space-y-8 size-full">
      <div className="flex flex-row space-x-2 items-center">
        <h2 className="text-heading-1">{t("Compliance")}</h2>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {[
          compliance.customer?.compliance.id,
          compliance.customer?.compliance.idQuizSummary,
          compliance.customer?.compliance.ofacStatus,
          compliance.customer?.compliance.redFlagStatus,
          compliance.customer?.compliance.recalls,
        ].map((e) => {
          return (
            <div
              key={e.title}
              className="flex flex-row justify-start items-center"
            >
              <Tooltip
                anchor={
                  <div>
                    <DataStatus status={toStatus(e.status)} />
                  </div>
                }
                content={
                  <div className="flex flex-col space-y-2">{e.description}</div>
                }
              />

              <div className="text-body text-dark-gray pl-6">{e.title}</div>
            </div>
          );
        })}
        {dealerData?.dealership?.hasEnabledIdVerifOverAgility && (
          <div className="flex flex-row justify-start items-center">
            <Tooltip
              anchor={
                <div>
                  {/* 
                    No data: nothing has been requested / Pending
                    No data: Pending
                    Complete: Positive ID verification
                    REQUIRES_ATTENTION: Error on verification
                  */}

                  <DataStatus
                    status={toStatus(
                      compliance.customer.compliance.hasRequestedIdv.status
                    )}
                  />
                </div>
              }
              content={
                <div className="flex flex-col space-y-2">
                  {compliance.customer.compliance.hasRequestedIdv.description}
                </div>
              }
            />

            <div className="text-body text-dark-gray pl-6">
              ID Verification
              {compliance.customer.compliance.hasRequestedIdv.status !==
                ComplianceStatus.OK && (
                <>
                  <Button
                    size="XSMALL"
                    variant="GHOST"
                    label="(Run Verification)"
                    onClick={() => setIsUserConfirmationModalOpen(true)}
                  />
                  <UserConfirmationModal
                    isOpen={isUserConfirmationModalOpen}
                    message={t(
                      "Are you sure you want to request an ID verification?"
                    )}
                    onClose={() => setIsUserConfirmationModalOpen(false)}
                    onConfirm={requestIdv}
                  />
                </>
              )}
              {compliance.customer.compliance.hasRequestedIdv.status ===
                ComplianceStatus.OK && (
                <IdvModal transactionId={transactionId} userId={userId} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withSuspense(
  TransactionCompliance,
  <div className="rounded-2xl bg-white shadow-md flex size-full justify-center align-middle">
    <Spinner />
  </div>
);
